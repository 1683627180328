import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GAME_STATE, JOIN_GAME, UPDATE_PLAYER_GYRO_AND_ORIENTATION } from '../graphql/game.graphql';
import { Game, GameStatus } from '../models/game.model';
import { HistogramData } from './gyroscope.service';
import { BehaviorSubject, catchError, map, Observable, Subject, takeUntil, throwError } from 'rxjs';

export type GameStateDetails = {
  id: string;
  status: GameStatus;
  lastTimestamp: number;
  teams: Array<{
    id: string
    name: string
    score: number
  }>
}

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  playerId: string;
  game: Game;


  constructor(
    private readonly apollo: Apollo,
  ) {
    const cachedPlayerId = localStorage.getItem('player_id');
    if (!!cachedPlayerId) {
      this.playerId = cachedPlayerId;
    }
    else {
      this.playerId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      localStorage.setItem('player_id', this.playerId);
    }
  }


  async updatePlayerGyroAndOrientation(gameId: string, gamePosition: string, histogramData: HistogramData): Promise<void> {
    if (!gameId || gameId.length === 0 || !gamePosition || gamePosition.length === 0) {
      return;
    }

    if (!histogramData) {
      return;
    }

    this.apollo.mutate({
      mutation: UPDATE_PLAYER_GYRO_AND_ORIENTATION,
      variables: {
        gameId: gameId,
        gamePosition: gamePosition,
        timestamp: Date.now(),
        histogramData: {
          count: histogramData.count,
          distance: histogramData.distance,
          duration: histogramData.duration,
          // ???: 2024-08-23
          // distance2: histogramData.distance2,
          // percentiles: histogramData.percentiles,
          sampleFrequency: histogramData.sampleFrequency,
          zValues: histogramData.zValues.map(d => {
            return {
              z: d.z,
              timestamp: d.timestamp
            }
          })
        },
      }
    }).subscribe({
      next: ({data}) => {
        // console.log('Updated player gyro and orientation', data);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  joinGame(gameId: string): Promise<Game> {
    if (!gameId || gameId.length === 0) {
      return Promise.reject('Invalid game ID');
    }

    return new Promise((resolve, reject) => {
      this.apollo.mutate({
        mutation: JOIN_GAME,
        variables: {
          gameId: gameId,
          playerInput: {
            id: this.playerId
          },
        }
      }).subscribe({
        next: ({data}) => {
          console.log('Joined game', data);
          const joinGameData = (data as any)?.joinGame;
          if (!!joinGameData) {
            this.game = new Game(joinGameData)
            resolve(this.game);
          }
        },
        error: (error) => {
          console.error(error);
          reject(error);
        }
      });
    });
  }

  private _gameSubscription: BehaviorSubject<GameStateDetails | null> = new BehaviorSubject<GameStateDetails | null>(null);
  private _gameKillSwitch$ = new Subject<void>();
  subscribeToGameState(game: Game | string): Observable<GameStateDetails> {
    let gameId: string = '';
    if (!game) {
      return this._gameSubscription;
    }
    if (game instanceof Game) {
      gameId = game.id;
      // this.game$.next(game);
    }
    else {
      gameId = game;
    }
    if (!!this._gameSubscription) {
      if (this._gameSubscription.getValue()?.id === gameId) {
        return this._gameSubscription;
      }
    }

    return this._subscribeToGameState(gameId);
  }

  private _gameInterval: any;
  private _subscribeToGameState(gameId: string): BehaviorSubject<GameStateDetails | null> {
    if (!!this._gameInterval) {
      clearInterval(this._gameInterval);
      this._gameInterval = null;
    }
    // Unsubscribe from the previous game
    if (!!this._gameSubscription) {
      // this.game$.next(null);
      this._gameKillSwitch$.next();
    }

    this.apollo
    .query({
      query: GAME_STATE,
      variables: {
        id: gameId,
      },
      fetchPolicy: 'no-cache',
    })
    .pipe(
      takeUntil(this._gameKillSwitch$),
      map(({ data }) => {
        console.log('Got game data', data);
        let gameData: any = (data as any)['fetchGame'];
        if (!!gameData) {
          return gameData;
        } else {
          console.error('Game not found');
          return null;
        }
      }),
      catchError((error) => {
        console.error(error);
        // Handle the error here, such as displaying an error message or performing any necessary cleanup
        return throwError(error); // Rethrow the error to propagate it to the subscriber
      })
    )
    .subscribe({
      next: (game) => {
        console.log('Got game data', game);
        this._gameSubscription.next(game);

        // Poll the game every few seconds
        this._gameInterval = setInterval(() => {
          this.apollo.query({
            query: GAME_STATE,
            variables: {
              id: gameId,
            },
            fetchPolicy: 'no-cache',
          }).subscribe({
            next: ({data}) => {
              console.log('Got game data', data);
              let gameData: any = (data as any)['fetchGame'];
              if (!!gameData) {
                this._gameSubscription.next(gameData);
              }
            },
            error: (error) => {
              console.error(error);
            }
          });
        }, 2500);
      },
      error: (error) => {
        console.error(error);
      }
    });

    return this._gameSubscription;
  }
}
