import { Component } from '@angular/core';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { GameController } from 'src/app/controllers/game.controller';
import { Scene } from 'src/app/models/game.model';
import { Question } from 'src/app/models/question.model';
import { BaseInteraction, CPRSession, EndVideo, FinalResultsInteraction, ResultsInteraction, SummaryInteraction, TeamScoreInteraction, VideoInteraction, VideoMarker, VideoMarkerType } from 'src/app/models/video-interaction.model';
import { NavControlService } from 'src/app/services/nav-control.service';

@Component({
  selector: 'app-video-menu',
  templateUrl: './video-menu.component.html',
  styleUrl: './video-menu.component.css'
})
export class VideoMenuComponent {

  FaTimes = faClose;

  constructor(
    private readonly gameController: GameController,
    private readonly navControl: NavControlService
  ) {

  }

  closeMenu() {
    this.navControl.closeMenu();
  }

  selectedScene: Scene = null;
  selectScene(event, scene: Scene) {
    if (!!event) {
      try {
        event.stopPropagation();
        event.preventDefault();
        event.stopImmediatePropagation();
      }
      catch (e) {
        // do nothing
      }
    }
    this.selectedScene = scene;
  }

  get scenes(): Scene[] {
    return this.gameController?.scenes || [];
  }

  get sceneQuestions(): Question[] {
    return this.selectedScene?.interactions.filter(i => (i instanceof Question)).map(i => i as Question) || [];
  }

  jumpToInteraction(event, interaction) {
    if (!!event) {
      try {
        event.stopPropagation();
        event.preventDefault();
        event.stopImmediatePropagation();
      }
      catch (e) {
        // do nothing
      }
    }
    let startMarker = this.getInteractionMarker(interaction, VideoMarkerType.START);
    this.gameController.game.currentInteractionIndices = [];
    if (startMarker) {
      this.gameController.setVideoTime(Math.max(0, startMarker.timestamp - 750));
      // this.seekVideo(Math.max(0, startMarker.timestamp - 500));
    }
  }

  getInteractionMarker(interaction: VideoInteraction, markerType: VideoMarkerType): VideoMarker | null {
    return interaction.markers.find(marker => marker.type === markerType)!;
  }

  asQuestion(interaction): Question {
    if (interaction instanceof Question) {
      return interaction;
    }
    return null;
  }

  asCprSession(interaction): CPRSession {
    if (interaction instanceof CPRSession) {
      return interaction;
    }
    return null;
  }

  asResults(interaction): ResultsInteraction {
    if (interaction instanceof ResultsInteraction) {
      return interaction;
    }
    return null;
  }

  asFinalResults(interaction): FinalResultsInteraction {
    if (interaction instanceof FinalResultsInteraction) {
      return interaction;
    }
    return null;
  }

  asTeamScore(interaction): TeamScoreInteraction {
    if (interaction instanceof TeamScoreInteraction) {
      return interaction;
    }
    return null;
  }

  asSummary(interaction): SummaryInteraction {
    if (interaction instanceof SummaryInteraction) {
      return interaction;
    }
    return null;
  }

  asEndVideo(interaction): EndVideo {
    if (interaction instanceof EndVideo) {
      return interaction;
    }
    return null;
  }

  asBaseInteraction(interaction): BaseInteraction {
    if (interaction instanceof BaseInteraction) {
      return interaction;
    }
    return null;
  }
}
