import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css'],
})
export class QuestionnaireComponent implements OnInit {

  constructor(
  ) {
  }

  async ngOnInit() {
    this.resetQuestionnaire();
  }

  private resetQuestionnaire() {
    // pick numQuestions random questions
    const shuffledQuestions = this.potentialQuestions.sort(() => 0.5 - Math.random());
    this.questions = shuffledQuestions.slice(0, this.numQuestions);

    this.currentQuestionIndex = 0;
    this.selectedAnswers = this.questions.map(() => null);
    this.isComplete = false;
    this.isPassing = false;
  }

  private numQuestions = 3;

  private potentialQuestions = [
    {
      question: 'What is the capital of France?',
      options: ['Berlin', 'Madrid', 'Paris', 'Lisbon'],
      correctIndex: 2,
    },
    {
      question: 'What is 2 + 2?',
      options: ['3', '4', '5', '6'],
      correctIndex: 1,
    },
    {
      question: 'Which planet is known as the Red Planet?',
      options: ['Earth', 'Mars', 'Venus', 'Jupiter'],
      correctIndex: 1,
    },
    {
      question: 'What is the largest mammal?',
      options: ['Elephant', 'Blue whale', 'Giraffe', 'Hippopotamus'],
      correctIndex: 1,
    }
  ];

  questions = [];

  currentQuestionIndex = 0;
  selectedAnswers: (number | null)[] = this.questions.map(() => null); // Store user answers
  isComplete = false;
  isPassing = false;

  get currentQuestion() {
    return this.questions[this.currentQuestionIndex];
  }

  questionAnswered(questionIndex: number) {
    return this.selectedAnswers[questionIndex] !== null;
  }

  selectOption(questionIndex: number, optionIndex: number) {
    this.selectedAnswers[questionIndex] = optionIndex;
  }

  goToNextQuestion() {
    if (this.currentQuestionIndex < this.questions.length - 1) {
      this.currentQuestionIndex++;
    } else {
      this.checkResults();
    }
  }

  goToPreviousQuestion() {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
    }
  }

  checkResults() {
    this.isComplete = true;
    this.isPassing = this.selectedAnswers.every(
      (answer, index) => answer === this.questions[index].correctIndex
    );
  }

  retry() {
    this.resetQuestionnaire();
  }

  ///////////////////////////////////
  // Square payment integration
  ///////////////////////////////////


  // Define your Square-hosted checkout URL
  private squareCheckoutUrl = 'https://checkout.square.site/buy/unique-checkout-id';
  purchaseCertificate() {
    window.location.href = this.squareCheckoutUrl;
  }

  // async pay() {
  //   const paymentIntent = await this.playerService.createPaymentIntent();
  //   const { error } = await this.stripe.confirmCardPayment(paymentIntent.client_secret, {
  //     payment_method: {
  //       card: this.cardElement,
  //       billing_details: {
  //         name: 'Jenny Rosen',
  //       },
  //     },
  //   });

  //   if (error) {
  //     console.error('Payment failed:', error);
  //   } else {
  //     console.log('Payment successful');
  //   }
  // }

}
