<div>
  <div class="video-container relative">
    <!-- <video #videoPlayer *ngIf="videoUrl" class="absolute inset-0" (timeupdate)="onVideoTimeUpdate($event)">
      (click)="togglePlayPause(true)"
      (loadedmetadata)="onVideoLoaded()" (ended)="onEnded()">
      <source [src]="videoUrl" type="video/mp4">
      Your browser does not support the video tag.
    </video -->
    <video #videoPlayer *ngIf="videoUrl" class="absolute inset-0"
      (click)="togglePlayPause(true)" [attr.loop]="null" (ended)="onEnded()"
      (loadedmetadata)="onVideoLoaded()">
      <source [src]="videoUrl" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <!-- <div class="cinema-crop-top"></div>
    <div class="cinema-crop-bottom"></div> -->


    <div
    class="absolute inset-0 bg-opacity-0 flex flex-col justify-center items-center transition-opacity duration-1000"
    [ngClass]="{
      'opacity-0 pointer-events-none': !showInteraction
    }">
      <div class="w-full h-[37.96%] bg-gradient-to-b from-black to-transparent absolute top-0 inset-x-0"></div>
      <div class="w-full bottom-0 h-[37.96%] bg-gradient-to-b from-transparent to-black absolute inset-x-0"></div>
    </div>

    <!-- <div *ngIf="(showStartButton || (subscribedToPlayerData$ | async) && (game?.numberOfPlayers || 0) <= 0) && !!game && game?.maxNumPlayers !== 0" -->
    <!-- <div *ngIf="(showJoinSessionUrl || (showStartButton && isActiveCprSession) || (subscribedToPlayerData$ | async) && (game?.numberOfPlayers || 0) <= 0) && !!game && game?.maxNumPlayers !== 0" -->
    <div *ngIf="(showJoinSessionUrl || (showStartButton && isActiveCprSession)) && !!game && game?.maxNumPlayers !== 0"
    class="absolute opacity-50 inset-y-0 flex flex-col justify-center items-center"
    [ngClass]="{
    'inset-x-0': currentTime <= 0,
    'right-4': currentTime > 0,
    }"
    >
      <qrcode [qrdata]="joinSessionUrl" [errorCorrectionLevel]="'M'" [width]="qrCodeSize"></qrcode>
    </div>

    <!-- Team Scores overlay -->
    <div *ngIf="teams?.length > 1" class="teams-container opacity-100"
      [ngClass]="{
        'opacity-0': !showTeamScores || showTeamScores && !teamScoresActive,
        'opacity-100 pointer-events-none': teamScoresActive && showTeamScores && !currentTeam,
        'opacity-100': teamScoresActive && showTeamScores && !!currentTeam
      }">
      <app-team-score *ngFor="let team of teams, let i = index" [team]="team"
        [teamIndex]="i" [containerDims]="videoPlayerDims" class="team-transition"
        >
      </app-team-score>
    </div>

    <!-- Team Interactive Displays -->
    <div *ngIf="subscribedToPlayerData$ | async" class="teams-interactive-container mx-4 mb-2 "
    [ngClass]="{'bg-black bg-opacity-25 min-h-[25px] w-full': (game?.numberOfPlayers || 0) <= 0}">
      <div class="flex flex-row justify-between items-center w-full">
        <app-team-interaction *ngFor="let team of game?.teams" [team]="team"></app-team-interaction>
      </div>
      <app-interactive-results *ngIf="!!gameController.selectedPlayer" class="w-full flex-grow" [histogram]="gameController.selectedPlayer?.histogramData"></app-interactive-results>
    </div>

    <div
      class="absolute inset-0 bg-opacity-0 flex flex-col justify-center items-center transition-opacity duration-1000"
      [ngClass]="{
        'opacity-0 pointer-events-none': !showInteraction
      }">
      <!-- <div class="absolute inset-0 bg-opacity-0" [ngClass]="{
          'bg-red-600 bg-opacity-20 border-[1.5vw] border-red-600': answerState === AnswerState.INCORRECT,
          'bg-[#FFD600] bg-opacity-20 border-[1.5vw] border-[#FFD600]': answerState === AnswerState.TIMEOUT
        }"></div> -->

      <div class="flex flex-col justify-between items-center top-[6.29%] bottom-[18.5%] inset-x-0 absolute">
        <!-- Question div placed at top of video -->
        <div *ngIf="!!currentQuestion" class="interaction-title-container">
          <div class="interaction-title" [innerHTML]="currentQuestion?.question | cleanText" (click)="pauseVideo(true)"></div>
        </div>

        <div class="question-outer-container relative">
          <div [ngClass]="{
            'opacity-0 pointer-events-none': ticker <= 0 && answerState !== AnswerState.TIMEOUT,
            'opacity-100': ticker > 0 || answerState === AnswerState.TIMEOUT
          }"
            class="inset-x-0 -bottom-4 absolute text-center text-[#FFD600] text-[10vw] font-normal font-digital-numbers leading-[1.175]">
            <span *ngIf="answerState === AnswerState.TIMEOUT">0</span>
            <span *ngIf="answerState !== AnswerState.TIMEOUT">{{ticker}}</span>
          </div>
          <app-question class="absolute inset-x-0 bottom-0 flex flex-row justify-between" *ngIf="!!currentQuestion"
            [question]="currentQuestion" [interactable]="interactable"
            (answerSubmitted)="onAnswerSubmitted($event)"></app-question>

        </div>
      </div>
    </div>

    <!-- feedback icons layering -->
    <!-- <div class="relative w-full h-full">
      <div class="absolute inset-0 flex flex-col justify-center items-center">
        <app-speedometer></app-speedometer>
      </div>
    </div> -->

    <!-- class="absolute inset-x-0 bottom-[3.29%] bg-opacity-50 flex flex-col justify-end items-center transition-opacity duration-1000" -->
    <div
      class="absolute inset-x-0 bottom-[7.5%] bg-opacity-50 flex flex-col justify-end items-center transition-opacity duration-1000"
      [ngClass]="{'opacity-0 pointer-events-none': !showStartButton, 'opacity-100': showStartButton}">
      <div *ngIf="!isActiveCprSession" class="flex flex-row justify-around items-center w-full">
        <button (click)="togglePlayPause(true)" class="video-button">Start</button>
      </div>
      <div *ngIf="isActiveCprSession" class="flex flex-col justify-center items-center w-full gap-2">
        <button (click)="togglePlayPause(true, {interaction: cprSession})" class="question-button">Start CPR</button>
      </div>
    </div>

    <div *ngIf="showControls" class="left-controls">
      <div *ngIf="showTimes" class="flex flex-col">
        <div>{{ (playTime$ | async) | number:'1.0-3' }} ms / {{ duration | number:'1.0-3' }} ms</div>
        <div>{{ formatTime(currentTime) }} / {{ formatTime(videoDuration) }}</div>
      </div>
      <fa-icon [icon]="FaQrCode" (click)="toggleQrCode()" class="px-2 py-2 cursor-pointer text-2xl"></fa-icon>
    </div>
    <div *ngIf="showControls" class="right-controls">
      <div class="flex flex-row justify-end items-center w-full">
        <button (click)="toggleMenu()" class="control-button">Menu</button>
      </div>
    </div>

    <app-video-menu class="absolute inset-0" *ngIf="showMenu"></app-video-menu>
  </div>
</div>
