import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { QuestionComponent } from './question/question.component';
import { CprPerformanceComponent } from './cpr-performance/cpr-performance.component';
import { ScoreboardComponent } from './scoreboard/scoreboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GyroscopeService } from './services/gyroscope.service';
import { TextToSpeechService } from './services/text-to-speech.service';
import { QRCodeModule } from 'angularx-qrcode';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { GameComponent } from './components/game/game.component';
import { TeamScoreboardComponent } from './components/team-scoreboard/team-scoreboard.component';
import { PlayerGameComponent } from './components/player-game/player-game.component';
import { HomeComponent } from './components/home/home.component';
import { NgChartsModule } from 'ng2-charts';
import { VideoInteractionComponent } from './components/video-interaction/video-interaction.component';
import { GameController } from './controllers/game.controller';
import { InteractiveResultsComponent } from './components/interactive-results/interactive-results.component';
import { TeamScoreComponent } from './components/video-interaction/team-score/team-score.component';
import { CleanTextPipe } from './pipes/clean-text.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TeamInteractionComponent } from './components/team-interaction/team-interaction/team-interaction.component';
import { PlayerInteractionComponent } from './components/team-interaction/player-interaction/player-interaction.component';
import { FeedbackIndicatorComponent } from './components/team-interaction/player-interaction/feedback-indicator/feedback-indicator.component';
import { RealTimePlotComponent } from './components/team-interaction/player-interaction/real-time-plot/real-time-plot.component';
import { PreGameComponent } from './components/game/pre-game/pre-game.component';
import { AutoResizeTextDirective } from './directives/auto-resize-text.directive';
import { VideoMenuComponent } from './components/video-menu/video-menu.component';
import { SpeedometerComponent } from './components/interactive-results/speedometer/speedometer.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';

@NgModule({
  declarations: [
    AppComponent,
    VideoPlayerComponent,
    QuestionComponent,
    CprPerformanceComponent,
    ScoreboardComponent,
    GameComponent,
    TeamScoreboardComponent,
    PlayerGameComponent,
    HomeComponent,
    VideoInteractionComponent,
    InteractiveResultsComponent,
    TeamScoreComponent,
    CleanTextPipe,
    TeamInteractionComponent,
    PlayerInteractionComponent,
    FeedbackIndicatorComponent,
    RealTimePlotComponent,
    PreGameComponent,
    AutoResizeTextDirective,
    VideoMenuComponent,
    SpeedometerComponent,
    QuestionnaireComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    GraphQLModule,
    NgChartsModule,
    FontAwesomeModule
  ],
  providers: [
    GyroscopeService,
    TextToSpeechService,
    GameController
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
