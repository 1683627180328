import { Component, Output, ViewChild, ElementRef, EventEmitter, HostListener, OnInit, OnDestroy } from '@angular/core';
import { CPRCompleteSession, Question } from '../models/question.model';
import { CPRSession, EndVideo, FinalResultsInteraction, InteractionDisplayType, VideoInteraction, VideoMarker, VideoMarkerType } from '../models/video-interaction.model';
import { Team } from '../models/team.model';
import { GameController } from '../controllers/game.controller';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AppService } from '../app.service';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { Game, GameStatus } from '../models/game.model';
import { NavControlService } from '../services/nav-control.service';

/**
 * CPR Interaction instructions: https://docs.google.com/document/d/1fiSfWJTzkvJrk3H2AgTOXfpvTyNUpdwP/edit?usp=drive_link&ouid=109018086231876061293&rtpof=true&sd=true
 */

export enum AnswerState {
  NONE,
  CORRECT,
  INCORRECT,
  TIMEOUT,
  UNANSWERED
}

export enum AnswerResponseType {
  CORRECT,
  INCORRECT,
  TIMEOUT,
  UNANSWERED
}

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit, OnDestroy {

  FaQrCode = faQrcode;

  showControls: boolean = true;
  showTimes: boolean = true;

  get showMenu(): boolean {
    return this.navControlService.showMenu
  }

  get videoUrl(): string {
    return this.gameController.game?.videoUrl || '';
  }

  get joinSessionUrl(): string {
    if (!this.gameController?.game) {
      return '';
    }
    return `${this.appService.baseUrl}/game/${this.gameController?.game.id}/player`;
  }

  showJoinSessionUrl: boolean = true;

  get teams(): Team[] {
    return this.gameController.game?.teams || [];
  }

  get currentTeam(): Team | null {
    return this.gameController.game?.currentTeam || null;
  }
  get currentTeamAnswering(): Team | null {
    if (!this.currentQuestion) {
      return null;
    }
    return this.gameController.teamAnswering || this.currentTeam;
  }
  get interactions(): VideoInteraction[] {
    return this.gameController.game?.interactions || [];
  }

  get game(): Game | null {
    return this.gameController?.game;
  }

  private _videoPlayer: ElementRef<HTMLVideoElement>;
  @ViewChild('videoPlayer') get videoPlayer(): ElementRef<HTMLVideoElement> {
    return this._videoPlayer;
  }
  private _videoPlayerBindingsCreated: boolean = false;
  set videoPlayer(value: ElementRef<HTMLVideoElement>) {
    if (!!this._videoPlayer) {
      const videoElement = this._videoPlayer.nativeElement;
      if (!!videoElement) {
        videoElement.removeEventListener('playing', this.handleVideoPlay.bind(this));
        videoElement.removeEventListener('pause', this._handleVideoStop.bind(this));
        videoElement.removeEventListener('ended', this._handleVideoStop.bind(this));
        this._videoPlayerBindingsCreated = false;
      }
    }

    this._videoPlayer = value;
    if (value && !this._videoPlayerBindingsCreated) {
      if (!this._videoPlayerBindingsCreated) {
        this._videoPlayerBindingsCreated = true;
        const videoElement = this._videoPlayer.nativeElement;
        // Add event listeners for playing, pause, and ended
        videoElement.addEventListener('playing', this.handleVideoPlay.bind(this));
        videoElement.addEventListener('pause', this._handleVideoStop.bind(this));
        videoElement.addEventListener('ended', this._handleVideoStop.bind(this)); // Stop checking when video ends
      }
      setTimeout(() => {
        this.updateVideoAndQrCodeSize();
      }, 1);
    }
  }
  get videoPlayerDims(): { width: number, height: number } {
    return {
      width: this.videoWidth,
      height: this.videoHeight
    };
  }

  @Output() sceneEnd = new EventEmitter<VideoInteraction>();
  @Output() start = new EventEmitter<void>();

  videoWidth = 0;
  videoHeight = 0;
  qrCodeSize = 0;

  AnswerState = AnswerState;

  // Listen for resize of this component
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVideoAndQrCodeSize();
  }

  private qrCodeSizeMultiplier: number = 0.525;

  private updateVideoAndQrCodeSize() {
    if (!!this.videoPlayer?.nativeElement) {
      this.videoWidth = this.videoPlayer.nativeElement.clientWidth;
      this.videoHeight = this.videoPlayer.nativeElement.clientHeight;
      this.qrCodeSize = Math.min(this.videoWidth, this.videoHeight) * this.qrCodeSizeMultiplier;
    }
  }


  constructor(
    protected gameController: GameController,
    private readonly appService: AppService,
    private readonly navControlService: NavControlService
  ) {}

  ngOnInit(): void {
    this.gameController.seekVideo.pipe(
      takeUntil(this.$destroy)
    ).subscribe({
      next: (time) => {
        this.seekVideo(time);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }


  private $destroy: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();

    if (!!this._videoPlayer) {
      const videoElement = this._videoPlayer.nativeElement;
      if (!!videoElement) {
        videoElement.removeEventListener('playing', this.handleVideoPlay.bind(this));
        videoElement.removeEventListener('pause', this._handleVideoStop.bind(this));
        videoElement.removeEventListener('ended', this._handleVideoStop.bind(this));
      }
    }
    this._videoPlayerBindingsCreated = false;
  }

  get showTeamScores(): boolean {
    let result: boolean = this.game?.firstInteractionTimestamp >= 0 && this.currentTime >= this.game?.firstInteractionTimestamp;
    return result;
  }
  get teamScoresActive(): boolean {
    const currentInteractions = this.gameController.game?.currentInteractions;
    if (this.game.maxNumPlayers === 0) {
      // if current interactions contain only a CPR Session or a CPR Complete Session, then do not show the team scores
      return currentInteractions?.some(i => !((i as any) instanceof CPRSession) && !((i as any) instanceof CPRCompleteSession)) ? true : false;
    }
    return this.game?.currentInteractions?.length > 0;
  }

  private _showInteraction: boolean = false;
  get showInteraction(): boolean {
    return this._showInteraction;
  }
  set showInteraction(value: boolean) {
    this._showInteraction = value;
    if (value) {
      this.interactionDisabled = false;
      // this.interactionComplete = false;
    }
  }
  interactionDisabled: boolean = false;
  // interactionComplete: boolean = false;

  get interactable(): boolean {
    return !this.interactionDisabled && this.gameController.currentInteractions?.some(i => !i.interactionComplete);
  }

  private _answerState: AnswerState = AnswerState.NONE;
  get answerState(): AnswerState {
    return this._answerState;
  }
  set answerState(value: AnswerState) {
    this._answerState = value;
  }

  videoDuration = 0;
  get duration(): number {
    return Math.round(this.videoPlayer?.nativeElement?.duration * 1000) || 0;
  }
  private isPlaying: boolean = false;

  currentTime = 0;
  timerTime = 0;

  onVideoLoaded() {
    this.videoDuration = Math.round(this.videoPlayer.nativeElement.duration * 1000);

    // Add a scene for the end of the video
    if (!!this.game?.scenes) {
      // if 'End' scene does not exist, then add it
      if (this.game.scenes.length === 0 || this.game.scenes[this.game.scenes.length-1].name !== 'End') {
        this.game.scenes.push({ start: this.videoDuration - 1000, end: this.videoDuration, name: 'End', interactions: [new EndVideo({displayType: InteractionDisplayType.RESULTS, markers: [
          new VideoMarker({offset: 0, timestamp: this.videoDuration - 1000, type: VideoMarkerType.START, id: 'START', value: 'Start'}),
          new VideoMarker({offset: 1000, timestamp: this.videoDuration, type: VideoMarkerType.END, id: 'END', value: 'End'}),
        ]})] });
      }
    }

  }

  private animationFrameId: number | null = null;
  // Method that checks the video time and triggers events
  startEventLoop() {
    if (!!this.animationFrameId) return;
    const videoElement = this.videoPlayer.nativeElement;

    const checkEvents = () => {
      this.onVideoTimeUpdate({});

      if (!videoElement.paused && !videoElement.ended) {
        this.animationFrameId = requestAnimationFrame(checkEvents);
      }
    };

    this.animationFrameId = requestAnimationFrame(checkEvents);
  }

  onVideoTimeUpdate(event?: any): void {
    this.isPlaying = !this.videoPlayer.nativeElement.paused;
    const nextTime = this.videoPlayer.nativeElement.currentTime * 1000;
    this.currentTime = nextTime;
    this.game.currentTimestamp = nextTime;

    if (this.currentTime > 0) {
      if (this.qrCodeSizeMultiplier !== 0.325) {
        this.qrCodeSizeMultiplier = 0.325;
        this.qrCodeSize = Math.min(this.videoWidth, this.videoHeight) * this.qrCodeSizeMultiplier;
      }
    }
    else {
      if (this.qrCodeSizeMultiplier !== 0.525) {
        this.qrCodeSizeMultiplier = 0.525;
        this.qrCodeSize = Math.min(this.videoWidth, this.videoHeight) * this.qrCodeSizeMultiplier;
      }
    }

    const newInteractions: VideoInteraction[] = this.interactions.filter(interaction => !this.gameController.game?.currentInteractions.some(ci => (ci as any).id === (interaction as any).id) && interaction.markers?.some(marker => marker.timestamp >= this.currentTime && marker.timestamp <= this.currentTime + GameController.TIME_OFFSET));
    if (newInteractions.length > 0) {
      if (newInteractions.some(i => (i as any) instanceof Question)) {
        this.answerState = AnswerState.UNANSWERED;
      }
      else if (newInteractions.some(i => (i as any) instanceof CPRSession)) {
        console.log(`CPR Session Started`);
      }
    }
    if (newInteractions.length > 0) {
      this.gameController.addCurrentInteractions(newInteractions);
    }

    if (this.game.currentInteractions.length > 0) {
      this.handleCurrentInteractions(nextTime);
      return;
    }
  }

  get subscribedToPlayerData$(): Subject<boolean> {
    return this.gameController.subscribedToPlayerData$;
  }

  get isActiveCprSession(): boolean {
    return this.gameController.currentCprSessions?.length > 0;
  }

  get cprSession(): CPRSession | null {
    return this.gameController.currentCprSessions[0] || null;
  }

  get showStartButton(): boolean {
    if (this.currentTime === 0) {
      return true;
    }
    if (this.isPlaying) {
      return false;
    }
    if (!this.isActiveCprSession) {
      return false;
    }
    if (this.pauseOptions?.interaction instanceof CPRSession) {
      return true;
    }
    return false;
    // return this.currentTime === 0 || !this.isPlaying && this.isActiveCprSession && (this._toggleOptions?.interaction instanceof CPRSession);
  }

  handleCurrentInteractions(currentTime: number = this.currentTime): boolean {
    if (!this.gameController.game?.currentInteractions.length) return false;
    if (this.interactionDisabled) return true;

    let jumpToTime: number | null = null;
    let jumpToInteraction: VideoInteraction | null = null;

    for(let currentInteraction of this.gameController.game.currentInteractions) {
      // if this is a jump then jump to the timestamp
      const jumpFrom = currentInteraction.markers.find(marker => marker.type === VideoMarkerType.JUMP_FROM);
      // We only jump if the jumpFrom timestamp is between the current time and the current time + GameController.TIME_OFFSET
      // if (!!jumpFrom && jumpFrom.timestamp >= currentTime && jumpFrom.timestamp <= currentTime + GameController.TIME_OFFSET) {
      if (!!jumpFrom && currentTime <= jumpFrom.timestamp && jumpFrom.timestamp <= (currentTime + GameController.TIME_OFFSET)) {
        const jumpMarker = currentInteraction.markers.find(marker => marker.type === VideoMarkerType.JUMP_TO);
        if (jumpMarker && jumpMarker.timestamp >= currentTime) {
          jumpToTime = jumpMarker.timestamp;
          jumpToInteraction = currentInteraction;
        }
      }
      if (currentInteraction instanceof CPRSession) {
        if (this.game.allowsPlayersToJoin) {
          // If not currently, subscribe to player data from the game controller
          this.gameController.subscribeToPlayerData();
        }
      }
      if (!currentInteraction.interactionComplete) {
        // Check to see if the current time is at a TICKER END indicating the question was not answered in time
        let tickerStopPoints = (currentInteraction.markers || []).filter(marker => marker.type === VideoMarkerType.TICKER_END);
        tickerStopPoints.forEach(tickerEndPoint => {
          // if (currentTime >= tickerEndPoint.timestamp && currentTime <= tickerEndPoint.timestamp + GameController.TIME_OFFSET) {
          if (currentTime <= tickerEndPoint.timestamp && tickerEndPoint.timestamp <= (currentTime + GameController.TIME_OFFSET)) {
            this.handleIncorrectAnswer(AnswerResponseType.TIMEOUT);
          }
        });

        // Check to see if we are at a TICKER START point
        let tickerStartPoints = (currentInteraction.markers || []).filter(marker => marker.type === VideoMarkerType.TICKER_START);
        tickerStartPoints.forEach(tickerStartPoint => {
          // if (currentTime >= tickerStartPoint.timestamp && currentTime <= tickerStartPoint.timestamp + GameController.TIME_OFFSET) {
          if (currentTime <= tickerStartPoint.timestamp && tickerStartPoint.timestamp <= (currentTime + GameController.TIME_OFFSET)) {
            // Get the ticker stop point for this ticker start point
            // tickerStopPoints.sort((a, b) => a.timestamp - b.timestamp); // Sort the stop points by timestamp
            const tickerStopPoint = tickerStopPoints.find(tickerStopPoint => tickerStopPoint.timestamp >= tickerStartPoint.timestamp);
            if (!!tickerStopPoint) {
              let tickerTime = Math.round((tickerStopPoint.timestamp - tickerStartPoint.timestamp) / 1000);
              this.startTicker(tickerTime);
            }
            else {
              this.startTicker();
            }
          }
        });

        // Check to see if the current time is at a TICKER STOP point
        tickerStopPoints.forEach(tickerStopPoint => {
          // if (currentTime >= tickerStopPoint.timestamp && currentTime <= tickerStopPoint.timestamp + GameController.TIME_OFFSET) {
          if (currentTime <= tickerStopPoint.timestamp && tickerStopPoint.timestamp <= (currentTime + GameController.TIME_OFFSET)) {
            this.stopTicker();
          }
        });

        // Check to see if the current time is at a PAUSE POINT
        // let pausePoints = (currentInteraction.markers || []).filter(marker => marker.type === VideoMarkerType.PAUSE_POINT || (currentInteraction instanceof CPRSession && marker.type === VideoMarkerType.START));
        let pausePoints = (currentInteraction.markers || []).filter(marker => marker.type === VideoMarkerType.PAUSE_POINT);
        pausePoints.forEach(pausePoint => {
          // if (currentTime >= pausePoint.timestamp && currentTime <= pausePoint.timestamp + GameController.TIME_OFFSET) {
          if (currentTime <= pausePoint.timestamp && pausePoint.timestamp <= (currentTime + GameController.TIME_OFFSET)) {
            // only pause if the current toggle interaction is NOT the current interaction
            if (!currentInteraction.interactionComplete && this.pauseOptions.interaction?.id !== currentInteraction?.id) {
              this.pauseVideo(false, {interaction: currentInteraction});
              if (currentInteraction instanceof CPRCompleteSession) {
                this.showInteraction = true
              }
            }
          }
        });

        // // check to see if there is a COMPLETE_SESSION point
        // if (currentInteraction instanceof CPRCompleteSession) {
        //   let completeSessionPoints = (currentInteraction.markers || []).filter(marker => marker.type === VideoMarkerType.PAUSE_POINT);
        //   completeSessionPoints.forEach(finishPoint => {
        //     if (currentTime >= finishPoint.timestamp && currentTime <= finishPoint.timestamp + GameController.TIME_OFFSET) {
        //       // only pause if the current toggle interaction is NOT the current interaction
        //       if (!currentInteraction.interactionComplete && this.pauseOptions.interaction?.id !== currentInteraction?.id) {
        //         this.pauseVideo(false, {interaction: currentInteraction});
        //         this.showInteraction = true;
        //       }
        //     }
        //   });
        // }
        // let completeSessionPoints = (currentInteraction.markers || []).filter(marker => marker.type === VideoMarkerType.COMPLETE_SESSION);
        // completeSessionPoints.forEach(finishPoint => {
        //   if (currentTime >= finishPoint.timestamp && currentTime <= finishPoint.timestamp + GameController.TIME_OFFSET) {
        //     // only pause if the current toggle interaction is NOT the current interaction
        //     if (!currentInteraction.interactionComplete && this.pauseOptions.interaction?.id !== currentInteraction?.id) {
        //       this.pauseVideo(false, {interaction: this.game.currentQuestion});
        //       this.showInteraction = true;
        //     }
        //   }
        // });
      }

      // Check to see if this is a fade in point
      let fadeInPoints = (currentInteraction.markers || []).filter(marker => marker.type === VideoMarkerType.FADE_IN);
      fadeInPoints.forEach(fadeInPoint => {
        // if (currentTime >= fadeInPoint.timestamp && currentTime <= fadeInPoint.timestamp + GameController.TIME_OFFSET) {
        if (currentTime <= fadeInPoint.timestamp && fadeInPoint.timestamp <= (currentTime + GameController.TIME_OFFSET)) {
          this.showInteraction = true;
        }
      });

      // Check to see if this is a fade out point
      let fadeOutPoints = (currentInteraction.markers || []).filter(marker => marker.type === VideoMarkerType.FADE_OUT);
      fadeOutPoints.forEach(fadeOutPoint => {
        // if (currentTime >= fadeOutPoint.timestamp && currentTime <= fadeOutPoint.timestamp + GameController.TIME_OFFSET) {
        if (currentTime <= fadeOutPoint.timestamp && fadeOutPoint.timestamp <= (currentTime + GameController.TIME_OFFSET)) {
          this.showInteraction = false;
        }
      });

      // Check for the end of the question
      let endPoints = (currentInteraction.markers || []).filter(marker => marker.type === VideoMarkerType.END);
      endPoints.forEach(endPoint => {
        // if (this.currentTime >= endPoint.timestamp && this.currentTime <= endPoint.timestamp + GameController.TIME_OFFSET) {
        // Regardless of how long after the end point the video is, if this endpoint has been reached, then end the interaction
        if (currentTime >= endPoint.timestamp) {
          console.log(`Ending Interaction ${currentInteraction.constructor.name}: ${currentInteraction.id}`);
          // show times
          console.log(`Current Time: ${currentTime}`);
          console.log(`End Time: ${endPoint.timestamp}`);
          console.log(`Time Offset: ${GameController.TIME_OFFSET}`);

          this.gameController.removeCurrentInteraction(currentInteraction);

          if (this.game.allowsPlayersToJoin) {
            // TODO: If there are no more CPR sessions, then stop subscribing to player data
            const isCprSession = this.gameController.game.currentInteractions.some(i => (i as any) instanceof CPRSession);
            if (!isCprSession) {
              console.log(`Unsubscribing from player data`);
              this.gameController.unsubscribeFromPlayerData();
            }
          }

          // Disable interaction if there are no more current Questions
          const isQuestion = this.gameController.game.currentInteractions.some(i => (i as any) instanceof Question);
          if (!isQuestion) {
            this.showInteraction = false;
          }
          this.sceneEnd.emit(currentInteraction);
          this.playVideo();
        }
      });
    }


    if (!!jumpToTime) {
      if (!!jumpToInteraction) {
        this.sceneEnd.emit(jumpToInteraction);
      }
      this.seekVideo(jumpToTime);
    }

    return true;
  }

  private correctAnswerAudio = new Audio('assets/sound_fx/at_right_answer_sfx.mp3');
  private incorrectAnswerAudio = new Audio('assets/sound_fx/at_wrong_answer_sfx.mp3');
  private incorrectAnswerCardiacAudio = new Audio('assets/sound_fx/at_cardiac_wrong_vo.mp3');
  private incorrectAnswerStarAudio = new Audio('assets/sound_fx/at_star_wrong_vo.mp3');

  private async playSound(sound: HTMLAudioElement): Promise<void> {
    return new Promise((resolve) => {
      // const sound = new Audio(soundPath);
      sound.onended = () => {
        resolve();
      };
      sound.play();
    });
  }


  private async playSoundsInSequence(sounds: HTMLAudioElement[]) {
    for (const sound of sounds) {
      await this.playSound(sound);
    }
  }

  get currentFinalResults(): FinalResultsInteraction | null {
    return this.gameController.game?.currentInteractions.find(i => (i as any) instanceof FinalResultsInteraction) as FinalResultsInteraction || null;
  }

  get currentQuestion(): Question | null {
    return this.gameController.game?.currentQuestion || null;
  }

  private _tickerValue: number = 0;
  get ticker(): number {
    if (!!this._tickerInterval) {
      return this._tickerValue;
    }
    return 0;
  }

  private _tickerInterval: any | null = null;
  async startTicker(tickerValue: number = 5, forceRestart: boolean = false) {
    if (this._tickerInterval) {
      if (!forceRestart) {
        return;
      }
      clearInterval(this._tickerInterval);
      this._tickerInterval = null;
    }
    this._tickerValue = tickerValue;
    this._tickerInterval = setInterval(() => {
      this._tickerValue -= 1;
      if (this._tickerValue <= 0) {
        this.stopTicker();
      }
    }, 1000);
  }

  async stopTicker() {
    this._tickerValue = 0;
    if (this._tickerInterval) {
      clearInterval(this._tickerInterval);
      this._tickerInterval = null;
    }
  }

  async handleCorrectAnswer(answer: string) {
    if (!this.gameController.game.currentInteractions.length) {
      return;
    }

    // get the current question
    let currentQuestion = this.gameController.game.currentQuestion;
    if (!currentQuestion) {
      return;
    }

    this.stopTicker();

    // Remove any controls
    currentQuestion.interactionComplete = true;
    this.interactionDisabled = true;
    this.answerState = AnswerState.CORRECT;

    this.gameController.handleCorrectAnswer(answer);

    this.pauseVideo(false, {interaction: this.game.currentQuestion});
    // Play the "correct answer" audio while simultaneously jumping to the end point timestamp in the video and playing (if the video is paused)
    await this.playSoundsInSequence([this.correctAnswerAudio]);
    let seekTime = (currentQuestion.markers || []).find(marker => marker.type === VideoMarkerType.PAUSE_POINT || marker.type === VideoMarkerType.COMPLETE_SESSION)?.timestamp || null;
    if (!!seekTime) {
      this.seekVideo(seekTime);
    }
    this.interactionDisabled = false;

    // If the question has no end point, then end the question
    if (!(currentQuestion.markers || []).some(marker => marker.type === VideoMarkerType.END)) {
      this.sceneEnd.emit(currentQuestion);
      this.gameController.removeCurrentInteraction(currentQuestion);
      this.showInteraction = false;
    }
  }

  async handleIncorrectAnswer(type: AnswerResponseType) {
    if (!this.gameController.game?.currentInteractions.length) {
      return;
    }

    // get the current question
    let currentQuestion = this.gameController.game.currentQuestion;

    this.stopTicker();

    if (type === AnswerResponseType.TIMEOUT) {
      this.answerState = AnswerState.TIMEOUT;
    }
    else {
      this.answerState = AnswerState.INCORRECT;
    }
    this.interactionDisabled = true;

    let secondAudio = this.incorrectAnswerCardiacAudio;
    let index = this.gameController.game.teams.indexOf(this.gameController.teamAnswering);
    if (index === 1) {
      secondAudio = this.incorrectAnswerStarAudio;
    }
    // if there is one or less teams, then just play the cardiac audio
    if (this.gameController.game.teams.length <= 1) {
      secondAudio = null;
    }

    this.pauseVideo(false);

    // Play the "incorrect answer" audio while simultaneously jumping to the start point timestamp in the video and playing (if the video is paused)
    await this.playSoundsInSequence([this.incorrectAnswerAudio, secondAudio].filter(sound => !!sound));
    this.seekVideo((currentQuestion.markers || []).find(marker => marker.type === VideoMarkerType.START)!.timestamp);
    this.interactionDisabled = false;
    this.answerState = AnswerState.UNANSWERED;

    // The next team gets to answer
    this.gameController.handleIncorrectAnswer();
  }

  onAnswerSubmitted(answer: string): void {
    if (!this.gameController.game?.currentInteractions.length || this.interactionDisabled) return;
    // get the current question
    let currentQuestion = this.gameController.game.currentQuestion;
    if (!currentQuestion) {
      return;
    }

    let correct = currentQuestion.checkAnswer(answer);
    if (correct) {
      this.handleCorrectAnswer(answer);
    }
    else {
      this.handleIncorrectAnswer(AnswerResponseType.INCORRECT);
    }
  }

  toggleMenu() {
    this.navControlService.toggleMenu();
  }

  private pauseOptions: {interaction?: VideoInteraction, jumpInterval?: number} = {};
  togglePlayPause(manual: boolean, options?: {interaction?: VideoInteraction, jumpInterval?: number}) {
    if (this.videoPlayer.nativeElement.paused) {
      this.showJoinSessionUrl = false;
      this.showControls = false;
      this.navControlService.closeMenu();

      this.pauseOptions = options || {};

      if (options?.jumpInterval > 0) {
        this.seekVideo(this.currentTime + options?.jumpInterval);
      }

      this.start.emit();
      this.playVideo();
    } else {
      this.pauseVideo(manual);
    }
  }
  pauseVideo(manual: boolean, options?: {interaction?: VideoInteraction, jumpInterval?: number}) {
    console.log(`PAUSING video: ${this.videoUrl}`);
    if (manual) {
      this.showControls = true;
    }
    this.pauseOptions = options || {};
    this.videoPlayer.nativeElement.pause();
    // this._handleVideoStop();
  }

  // this is triggered by the event listener for the 'pause' event of the video element
  private _handleVideoStop() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }

    this.isPlaying = false;
    this.game.currentPaused = true;
    // save the game
    this.gameController.saveGame();

    this._pauseTime = Date.now();

    if (this._startTime > 0) {
      this._totalPlayTime += this._pauseTime - this._startTime;
    }
    if (this._startTimeInterval) {
      clearInterval(this._startTimeInterval);
      this._startTimeInterval = null;
    }
    this._startTime = 0;
  }

  private _startTime: number = 0;
  private _pauseTime: number = 0;
  private _pauseDuration: number = 0;
  private _totalPlayTime: number = 0;
  private _startTimeInterval: any | null = null;
  playTime$: Subject<number> = new BehaviorSubject<number>(0);
  get playTime(): number {
    return this._totalPlayTime + (this._startTime > 0 ? Date.now() - this._startTime : 0);
  }

  playVideo() {
    if (!this.videoPlayer) return;

    // this.game.currentPaused = false;

    // // if we have no current cpr session, then unsubscribe from player data
    // if (!this.gameController.game.currentInteractions.some(i => (i as any) instanceof CPRSession)) {
    //   this.gameController.unsubscribeFromPlayerData();
    // }
    // else {
    //   this.gameController.subscribeToPlayerData();
    // }

    // this.showJoinSessionUrl = false;
    // this.showControls = false;
    // this.navControlService.closeMenu();
    // if (this._startTimeInterval) {
    //   clearInterval(this._startTimeInterval);
    //   this._startTimeInterval = null;
    // }
    // if (this._pauseTime > 0) {
    //   this._pauseDuration += Date.now() - this._pauseTime;
    // }
    // this._pauseTime = 0;
    // this._startTime = Date.now();
    // this._startTimeInterval = setInterval(() => {
    //   this.playTime$.next(this.playTime);
    // }, 10);
    // console.log(`playing video: ${this.videoUrl}`);
    // this.isPlaying = true;

    // if (!this.videoPlayer.nativeElement.paused) return;
    this.videoPlayer.nativeElement.play();
    // this.startEventLoop();

    // if the game status is not started, then emit the start event
    if (this.game.status !== GameStatus.IN_PROGRESS || !!this.game.dateEnded) {
      this.game.dateEnded = null;
      this.game.status = GameStatus.IN_PROGRESS;
      this.gameController.saveGame();
    }
  }

  // this is triggered by the event listener for the 'playing' event of the video element
  private handleVideoPlay() {
    this.game.currentPaused = false;

    // if we have no current cpr session, then unsubscribe from player data
    if (this.gameController.game.allowsPlayersToJoin) {
      if (!this.gameController.game.currentInteractions.some(i => (i as any) instanceof CPRSession)) {
        this.gameController.unsubscribeFromPlayerData();
      }
      else {
        this.gameController.subscribeToPlayerData();
      }
    }

    this.showJoinSessionUrl = false;
    this.showControls = false;
    this.navControlService.closeMenu();
    if (this._startTimeInterval) {
      clearInterval(this._startTimeInterval);
      this._startTimeInterval = null;
    }
    if (this._pauseTime > 0) {
      this._pauseDuration += Date.now() - this._pauseTime;
    }
    this._pauseTime = 0;
    this._startTime = Date.now();
    this._startTimeInterval = setInterval(() => {
      this.playTime$.next(this.playTime);
    }, 10);
    console.log(`playing video: ${this.videoUrl}`);
    this.isPlaying = true;

    this.startEventLoop();

        // save the game
        this.gameController.saveGame();

    if (!this.videoPlayer.nativeElement.paused) return;
    // this.videoPlayer.nativeElement.play();
  }

  seekVideo(time?: number, play: boolean = true) {
    let currentVideoTime = Math.round(this.videoPlayer.nativeElement.currentTime * 1000.0);

    if (!!time) {
      this.currentTime = time;
      this.game.currentTimestamp = time;
    }

    // Update the _totalPlayTime
    this._totalPlayTime += this.currentTime - currentVideoTime;

    this.videoPlayer.nativeElement.currentTime = this.currentTime / 1000.0;
    if (play) {
      this.playVideo();
    }
    else {
      this.showJoinSessionUrl = false;
      this.showControls = false;
      this.navControlService.closeMenu();
    }
  }

  formatTime(time: number): string {
    const milliseconds = Math.floor(time % 1000);
    const t = Math.floor(time / 1000);
    const minutes = Math.floor(t / 60);
    const seconds = Math.floor(t % 60);
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}.${milliseconds}`;
  }

  onQuestionAnswered() {
    // get the current question
    let currentQuestion = this.gameController.game.currentQuestion;
    if (currentQuestion) {
      this.gameController.removeCurrentInteraction(currentQuestion);
    }
    this.playVideo();
  }

  // This is a mock implementation of getting sceneEnd questions, replace it with your actual implementation.
  getSceneEndQuestions(): VideoInteraction[] {
    return this.interactions;
  }

  async onEnded(): Promise<void> {
    this.game.status = GameStatus.FINISHED;
    this.game.dateEnded = new Date();
    await this.gameController.saveGame();
    // // this._handleVideoStop();
    // const questions = this.getSceneEndQuestions();
    // if (questions.length > 0) {
    //   this.sceneEnd.emit(questions[0]);
    // }
  }

  onSceneEndAnswered(): void {
    this.playVideo();
  }

  VideoMarkerType = VideoMarkerType;
  getInteractionMarker(interaction: VideoInteraction, markerType: VideoMarkerType): VideoMarker | null {
    return interaction.markers.find(marker => marker.type === markerType)!;
  }

  jumpToInteraction(interaction) {
    let startMarker = this.getInteractionMarker(interaction, VideoMarkerType.START);
    this.gameController.game.currentInteractionIndices = [];
    if (startMarker) {
      this.seekVideo(Math.max(0, startMarker.timestamp - 500));
    }
  }

  toggleQrCode() {
    this.showJoinSessionUrl = !this.showJoinSessionUrl;
  }

}
