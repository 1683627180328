<div class="min-h-screen bg-black flex flex-col items-center justify-center px-4">
  <div class="bg-orange-500 shadow-md rounded-lg w-full max-w-md p-6 m-6 relative">
    <!-- Progress Bar -->
    <div class="absolute top-0 left-0 w-full h-1 bg-white rounded-t-lg overflow-hidden">
      <div
        class="h-full bg-green-500 transition-all duration-300"
        [style.width]="((currentQuestionIndex + 1) / questions.length) * 100 + '%'"
      ></div>
    </div>

    <ng-container *ngIf="!isComplete">
      <!-- Header -->
      <h2 class="text-xl font-bold text-gray-900 text-center mb-6">Quiz</h2>

      <!-- Question -->
      <div class="text-lg text-gray-900 font-medium text-center mb-8">
        {{ currentQuestion.question }}
      </div>

      <!-- Options -->
      <div class="space-y-4">
        <button
          *ngFor="let option of currentQuestion.options; let i = index"
          class="block w-full px-4 py-3 border rounded-lg text-left font-medium text-gray-900 hover:bg-black hover:text-orange-500 hover:border-orange-500 transition "
          [ngClass]="{
            'bg-black text-orange-500 border-orange-500': selectedAnswers[currentQuestionIndex] === i,
            'bg-orange-500 text-black border-black': selectedAnswers[currentQuestionIndex] !== i
          }"
          (click)="selectOption(currentQuestionIndex, i)"
        >
          {{ option }}
        </button>
      </div>

      <!-- Navigation -->
      <div class="mt-6 flex justify-between">
        <button
          class="bg-orange-500 text-black py-2 px-4 rounded-lg shadow hover:bg-orange-600 border-black"
          [disabled]="currentQuestionIndex === 0"
          (click)="goToPreviousQuestion()"
        >
          Previous
        </button>
        <button
          class="bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 border-black"
          [disabled]="!questionAnswered(currentQuestionIndex)"
          (click)="goToNextQuestion()"
        >
          {{ currentQuestionIndex === questions.length - 1 ? 'Submit' : 'Next' }}
        </button>
      </div>
    </ng-container>

    <!-- Result Screen -->
    <ng-container *ngIf="isComplete">
      <h2 class="text-xl font-bold text-gray-800 text-center mb-6">
        {{ isPassing ? '🎉 Congratulations! You Passed!' : 'Oops! Try Again!' }}
      </h2>

      <p class="text-lg text-gray-700 text-center mb-8">
        {{ isPassing
          ? 'You got all answers correct. Great job!'
          : 'You can retake the quiz and try again.' }}
      </p>

      <button
      *ngIf="isPassing"
      class="bg-blue-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-600 block mx-auto mb-4"
      (click)="purchaseCertificate()"
    >
      Purchase Certificate
    </button>

    <button
    *ngIf="!isPassing"
    class="bg-blue-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-600 block mx-auto"
        (click)="retry()"
      >
        Retake Quiz
      </button>
    </ng-container>
  </div>
</div>
